@import 'src/styles/breakpoints';

.landingPageSearch {
  margin-top: -13.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  padding: var(--spacing-l) var(--spacing-m);
  z-index: 1;
  position: absolute;
  width: 100%;

  @include respond-above(s) {
    padding: var(--spacing-xl);
  }

  @include respond-below(s) {
    position: relative;
  }

  h1 {
    font-size: var(--fontsize-heading-xl);
    font-weight: 500;
    margin-top: 0;
    margin-bottom: var(--spacing-l);
    color: var(--color-white);
  }
}

.categoriesWrapper {
  position: relative;
  top: 2.8rem;
  z-index: 0;
  padding-top: 4rem;
  padding-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  height: fit-content;
  align-items: center;

  @include respond-below(m) {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    grid-gap: 2px;
    padding-bottom: 3rem;
  }

  @include respond-above(s) {
    padding-top: 12rem;
  }

  @include respond-above(m) {
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 4rem;
  }

  @include respond-above(l) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  div:nth-child(10) {
    @include respond-below(m) {
      grid-column-start: 2;
    }
  }
}
